<template>
  <div class="operational-main">
    <b-row>
      <b-col sm="9">
        <div class="div-title text-left">
          <br />
          <span class="title-main">Faturamento</span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="3">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-output" @click="navegateTo('toProfitListFilter', filter.id)">
                <i class="fa-solid fa-boxes-stacked icon-fontawesome"></i>
              </div>
              <div class="total text-center">{{ data.totalProducts }}</div>
              <div class="legend text-center">Produtos</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="3">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-invoice" @click="navegateTo('toProfitListFilter', filter.id)">
                <i class="fa-solid fa-dollar-sign icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.totalInvoice | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">A Faturar</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="3">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-danger" @click="navegateTo('toProfitListFilter', 'delayed')">
                <i class="fa-solid fa-dollar-sign icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ data.totalDelayed | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Em Atraso</div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="3">
            <div class="panel-child panel-output">
              <div class="panel-icon panel-icon-total" @click="navegateTo('toProfitListFilter', filter.id)">
                <i class="fa-solid fa-dollar-sign icon-fontawesome"></i>
              </div>
              <HideShow :iconSize="18">
                <div style="font-size: 18px" class="title text-center">
                  {{ (data.totalInvoice + data.totalDelayed) | currency }}
                </div>
              </HideShow>
              <div class="legend text-center">Total</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PanelOperational",
  components: {
    HideShow,
  },
  data() {
    return {
      urlGet: "/api/v1/billing/invoice/total-dashboard",
      data: {},
      filter: { content: "Hoje", id: "today" },
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      let params = { url: this.urlGet, obj: { any: this.filter.id } };
      this.getApi(params).then((response) => {
        this.data = response.content;
      });
    },
    navegateTo(routeName, status) {
      this.$router.push({
        name: routeName,
        params: { status: status }
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "filterDashboard") {
          this.filter = event.data;
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.operational-main {
  margin-left: -10px;
}

.div-title {
  margin-bottom: 15px;
}

.panel-child {
  height: 130px !important;
  width: 95% !important;
  border-radius: 20px;
  margin-left: 18px;
  margin-bottom: 10px;
  padding: 10px;
}

.panel-icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.panel-icon-output {
  background-color: darkblue;
}

.panel-output {
  background-color: white;
  border: 1px solid #e8eaed;
}

.panel-icon-invoice {
  background-color: #006400;
}

.panel-icon-danger {
  background-color: red;
}

.panel-icon-total {
  background-color: blue;
}

.icon-fontawesome {
  color: #fdfdfd;
  font-size: 14px;
}

.title-main {
  font-size: 20px;
  margin-left: 20px;
}

.total {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-top: 1px !important;
}

.legend {
  font-size: 16px;
}
</style>